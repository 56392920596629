/**
 * 获取第一个表格的可视化高度
 * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74) 
 * @param {*} id 当前页面中有多个table时须要制定table的id
 */
function getTableScroll (extraHeight: number = 140, id?: string) {

  let tHeader = null
  if (id) {
    tHeader = document.getElementById(id) ? document.getElementById(id)?.getElementsByClassName("ant-table-thead")[0] : null
  } else {
    tHeader = document.getElementsByClassName("ant-table-thead")[0]
  }

  //表格内容距离顶部的距离
  let tHeaderBottom = 0
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom
  }
  //窗体高度-表格内容顶部的高度-表格内容底部的高度
  // let height = document.body.clientHeight - tHeaderBottom - extraHeight
  // let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
  let height = document.body.clientHeight - tHeaderBottom - extraHeight
  return height
}


function getGridScroll (extraHeight: number = 0, id?: string) {

  let tHeader = null
  if (id) {
    tHeader = document.getElementById(id) ? document.getElementById(id)?.getElementsByClassName("pear-tab-1")[0] : null
  } else {
    tHeader = document.getElementsByClassName("pear-tab-1")[0]
  }

  // console.log('document.getElementsByClassName("vxe-grid")[0]')
  // console.log(document.getElementsByClassName("table-page-search-wrapper")[0])

  //表格内容距离顶部的距离
  let tHeaderBottom = 0
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom
  }

  // console.log(tHeader.getBoundingClientRect().bottom)

  //窗体高度-表格内容顶部的高度-表格内容底部的高度
  // let height = document.body.clientHeight - tHeaderBottom - extraHeight
  // let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
  let height = document.body.clientHeight - tHeaderBottom - extraHeight - 30
  return height
}

function getComponentScroll (id: string, extraHeight: number = 0) {

  let tHeader = document.getElementById(id)

  //表格内容距离顶部的距离
  let tHeaderBottom = tHeader!.getBoundingClientRect().bottom

  let height = document.body.clientHeight - tHeaderBottom - extraHeight

  return height

}


export default {
  getTableScroll,
  getGridScroll,
  getComponentScroll
}